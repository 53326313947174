@use 'global' as *;
@mixin card-hover {
  border-radius: var(--global-border-radius);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: box-shadow 200ms ease, transform 200ms ease-in-out;

  .text_container {
    background-color: var(--color-white);
    color: var(--color-off-black);
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 2.5rem;

    p {
      color: var(--color-anvil);
    }
  }

  .button_container {
    display: flex;
    justify-content: flex-start;
    margin-top: auto;
  }

  @include hover {
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
    text-decoration: none;
    transform: translateY(-8px) translateZ(0);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }

  * {
    cursor: pointer;
  }
}

// --------------------------------------------------------- / text container

.label_container {
  align-items: center;
  color: var(--color-anvil);
  display: flex;
  flex-direction: row;
  margin: 6px 0 12px;

  .label_icon {
    color: var(--color-lagoon);
    margin-right: 0.5rem;
    width: 20px;
  }

  .pipe_icon {
    color: var(--color-prime-teal);
    height: 20px;
    margin: 0 6px;
    width: 7px;
  }

  p {
    @include h6;

    margin: 0;
  }
}

.text_container {
  height: 100%;
  padding-bottom: 20px;
  padding-top: 10px;
  white-space: pre-wrap;

  a h4 {
    color: var(--color-off-black);
  }
}

.grid_card_title {
  @include hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

// --------------------------------------------------------- / "fake" button

.arrow_dark {
  @include arrow;
}

.arrow_light {
  @include arrow;

  color: var(--color-white);

  &::before {
    background: var(--color-white);
  }

  &::after {
    border-right: 1.5px solid var(--color-white);
    border-top: 1.5px solid var(--color-white);
  }

  &:hover {
    color: var(--color-amp-yellow);

    &::before {
      background: var(--color-amp-yellow);
    }

    &::after {
      border-right: 1.5px solid var(--color-amp-yellow);
      border-top: 1.5px solid var(--color-amp-yellow);
    }
  }
}

.large_image {
  height: auto;
  width: 100%;
}

.card_svg {
  svg {
    height: auto;
    width: 100%;
  }
}

// --------------------------------------------------------- / Themes

.theme_default {
  height: auto;
  max-width: 100%;

  a > div {
    color: var(--color-off-black);
    overflow: hidden;
    width: 100%;

    img {
      overflow: hidden;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  @include media($small, down) {
    width: 100%;
  }

  h4 {
    margin-bottom: 0.8rem;
  }
}

.theme_hover {
  border: 1px solid var(--color-cement);

  @include card-hover;

  @include hover {
    border: 1px solid var(--color-amp-yellow);

    h4 {
      color: var(--color-dusk);
    }
  }
}

.theme_hover_dark {
  @include card-hover;

  @include hover {
    border: 0;
    box-shadow: 0 4px 20px rgb(223 249 65 / 50%);
  }
}

.theme_carousel {
  @include card-hover;

  box-shadow: var(--global-box-shadow);

  .text_container {
    padding: 1rem 1rem 1.8rem !important;
  }

  @include media($small-lowest) {
    .button_container {
      margin-top: 0;
      opacity: 0%;
      transition: opacity 200ms ease-in;
    }

    @include hover {
      .button_container {
        opacity: 100%;
        transition: opacity 200ms ease-in;
      }
    }
  }
}

.theme_color_split {
  border-radius: 10px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;
  max-height: 480px;
  max-width: 400px;
  min-width: 280px;

  .card_image_container {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    max-width: 100% !important;

    svg {
      max-height: 150px;
      max-width: 150px;
      padding-left: 1rem;
    }

    div {
      align-items: center;
      display: grid;
      height: 100%;
      width: 100%;
    }
  }

  .text_container {
    background-color: var(--color-midnight);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    padding: 1.5rem !important;

    h4 {
      color: var(--color-white);
      margin-bottom: 10px;
    }

    p {
      color: var(--color-white) !important;
    }

    .button_container_border {
      align-items: center;
      border: 3px solid var(--color-lagoon);
      border-radius: 50px;
      display: flex;
      height: 50px;
      justify-content: space-between;
      margin-top: auto;
      transition: border-color 200ms ease;

      p {
        color: var(--color-ice);
        font-size: 16px;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 0;
        margin-left: 1rem;
      }

      span {
        font-weight: 100;
      }
    }
  }

  &.theme_hover {
    border: 0;
  }
}

.theme_color_split:hover {
  &.theme_hover {
    border: 0;
    cursor: pointer;

    h4 {
      color: white;
    }

    .button_container_border {
      border-color: var(--color-dusk);

      p span {
        color: var(--color-prime-gray);
      }
    }

    .open_button {
      background-color: var(--color-dusk);
      cursor: pointer;
    }

    .text_container {
      h4,
      p {
        cursor: pointer;
      }
    }
  }
}

.theme_prime_red {
  .card_image_container {
    background-color: var(--color-prime-red);
  }

  .card_svg {
    color: var(--color-deep-red);
  }
}

.theme_prime_green {
  .card_image_container {
    background-color: var(--color-prime-green);
  }

  .card_svg {
    color: var(--color-deep-green);
  }
}

.theme_limoncello {
  .card_image_container {
    background-color: var(--color-limoncello);
  }

  .card_svg {
    color: var(--color-moss);
  }
}

.theme_white {
  .card_image_container {
    background-color: var(--color-white);
  }

  .card_svg {
    color: var(--color-moss);
  }
}

.theme_white_background {
  background-color: var(--color-white);
  border-radius: var(--global-border-radius);

  img {
    border-radius: var(--global-border-radius) var(--global-border-radius) 0 0;
  }

  .text_container {
    color: var(--color-off-black);
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 2.5rem;

    p {
      color: var(--color-anvil);
    }
  }
}

.theme_off_black {
  background-color: var(--color-off-black);

  .text_container {
    background-color: var(--color-off-black);
  }
}

.button_container_single {
  align-self: flex-end;
  display: flex;
  margin-top: auto;
}

.open_button {
  align-items: center;
  background-color: var(--color-lagoon);
  border-radius: 50%;
  color: var(--color-midnight);
  display: flex;
  height: 35px;
  justify-content: center;
  margin-right: 0.25rem;
  transform: rotate(45deg);
  transition: background-color 200ms ease;
  width: 35px;

  svg {
    height: 15px;
    width: 15px;
  }
}
